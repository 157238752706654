<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo />
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                    lg="8"
                    class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"/>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                    lg="4"
                    class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <b-col
                        sm="8"
                        md="6"
                        lg="12"
                        class="px-xl-2 mx-auto"
                >
                    <b-card-title
                            title-tag="h3"
                            class="font-weight-bold mb-1"
                    >
                        Password recovery e-mail sent!
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Please check your E-mail and follow the password reset link!
                    </b-card-text>

                            <b-button
                                    type="submit"
                                    variant="outline-primary"
                                    block
                                    @click="goBack()"
                            >
                                Back to Login
                            </b-button>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
    /* eslint-disable global-require */
    import VuexyLogo from '@core/layouts/components/Logo.vue';
    import {
        BRow,
        BCol,
        BLink,
        BCardText,
        BCardTitle,
        BButton,
    } from 'bootstrap-vue';
    export default {
        components: {
            BRow,
            BCol,
            BLink,
            BCardText,
            BCardTitle,
            BButton,
            VuexyLogo,
        },
        data() {
            return {

            }
        },
        methods: {
            goBack(){
                this.$router.push({name:"login"});
            },
        }
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
